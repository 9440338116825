<template>
  <el-dialog
    title="融资进度"
    :visible.sync="dialogVisible"
    width="500px"
    :close-on-click-modal="false"
    class="br-dialog"
  >
    <el-table :border="true" :data="financingProgress" class="br-table" style="width: 100%">
      <el-table-column label="时间" prop="createTime">
        <template slot-scope="scope">
          {{ scope.row.createTime }}
        </template>
      </el-table-column>
      <el-table-column label="节点" prop="cmpNodeName" />
      <el-table-column label="融资状态" prop="nodeName" />
    </el-table>
  </el-dialog>
</template>
<script>
import { selectFinanceSpeed } from '@/api/tradeFinancing.js'
export default {
  props: {
    echainId: {
      type: Number,
      default: () => (0)
    }
  },
  data() {
    return {
      dialogVisible: false,
      financingProgress: []
    }
  },
  watch: {
    dialogVisible(newV) {
      if (newV) {
        selectFinanceSpeed(this.echainId, res => {
          this.financingProgress = res.data
        })
      }
    }
  }
}
</script>
