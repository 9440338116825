<template>
  <el-dialog
    title="文件列表"
    :visible.sync="dialogVisible"
    width="500px"
    :close-on-click-modal="false"
    class="br-dialog"
  >
    <el-table :border="true" :data="pdfListInfo" class="br-table" style="width: 100%">
      <el-table-column label="文件名称" prop="fileName" />

      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button size="mini" type="success" class="table-detail-button" @click="downloadPdf(scope.row.url, scope.row.fileName)">
            查看
            <!-- <a :href="downloadPdf(scope.row.url, scope.row.fileName)" :download="scope.row.fileName">查看</a> -->
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
import { downloadFile } from '@/utils/util'
import { selectPdfList } from '@/api/tradeFinancing.js'
export default {
  props: {
    echainId: {
      type: Number,
      default: () => (0)
    }
  },
  data() {
    return {
      dialogVisible: false,
      pdfListInfo: []
    }
  },
  watch: {
    dialogVisible(newV) {
      if (newV) {
        selectPdfList(this.echainId, res => {
          this.pdfListInfo = res.data
        })
      }
    }
  },
  methods: {
    downloadPdf(url, name) {
      downloadFile(url, name)
    }
  }
}
</script>
